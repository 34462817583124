import { Button, Icon, IconEUFlag, Link, Paragraph } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import * as React from 'react';

import { shouldAskForConsent, setConsentCookie } from '../../lib/util/cookie_consent';
import { EmbedDataContext } from '../embed_data_context';

import styles from './styles.module.css';

interface State {
  isOpen: boolean;
}

interface Props {
  onUserConsentChanged?: (isTrackingAccepted: boolean) => void;
}

export class TrackingConsentPopup extends React.Component<Props, State> {
  state = {
    isOpen: false,
  };

  static contextType = EmbedDataContext;
  declare context: React.ContextType<typeof EmbedDataContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: shouldAskForConsent(),
    };
  }

  getCookieDomain = () => this.context.embedConfig?.cookie_domain;

  handleClickAllow = () => {
    const cookieDomain = this.getCookieDomain();
    setConsentCookie({ isConsentGiven: true, cookieDomain });
    this.setState({ isOpen: false });

    if (this.props.onUserConsentChanged !== undefined) {
      this.props.onUserConsentChanged(true);
    }
  };

  handleClickDisallow = () => {
    const cookieDomain = this.getCookieDomain();
    setConsentCookie({ isConsentGiven: false, cookieDomain });
    this.setState({ isOpen: false });

    if (this.props.onUserConsentChanged !== undefined) {
      this.props.onUserConsentChanged(false);
    }
  };

  render() {
    return (
      this.state.isOpen && (
        <div className={styles.popup}>
          <IconEUFlag className={styles.euFlag} />
          <div className={styles.body}>
            <Paragraph size="large">
              <Localized id="component.trackingConsentPopup.description">
                We continuously improve our services by using insight gained from gathering data on the usage of our
                website and applications. We would appreciate if you allowed us to do so. You can read more on how this
                works in our{' '}
                <Link href="https://sixfold.com/privacy" target="_blank">
                  Privacy Policy
                </Link>
              </Localized>
            </Paragraph>
            <Button kind="primary" size="medium" className={styles.allow} onClick={this.handleClickAllow}>
              <Localized id="component.trackingConsentPopup.button.allow">Allow</Localized>
            </Button>
          </div>
          <Localized id="component.trackingConsentPopup.button.disallow" defaultValue="Do not consent">
            {(title) => (
              <button title={title} className={styles.disallow} onClick={this.handleClickDisallow}>
                <Icon name="small-cross" />
              </button>
            )}
          </Localized>
        </div>
      )
    );
  }
}
