import { ErrorHandlerProviderProps } from '@sixfold/app-utils/dist/types/error_handler.d';

import { getEmbedConfig } from './lib/data';

const embedConfig = getEmbedConfig();
const sentryOptions: ErrorHandlerProviderProps['sentryOptions'] =
  embedConfig !== null && embedConfig.sentry_dsn
    ? {
        isEnabled: true,
        isHttpClientCaptureEnabled: true,
        dsn: embedConfig.sentry_dsn,
        release: embedConfig.release,
        environment: embedConfig.sentry_environment,
        thirdPartyErrorFilter: {
          applicationKey: embedConfig.sentry_app_key ?? 'customer-web-appkey',
        },
        ignoreErrors: [
          /ResizeObserver loop limit exceeded/,
          /ResizeObserver loop completed with undelivered notifications/,
          // Fix by: https://app.asana.com/0/1202007030618467/1205798219208199
          /Response not successful: Received status code 401/,
        ],
      }
    : { isEnabled: false };

export { sentryOptions };
