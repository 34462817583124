import { gql } from '@sixfold/app-data-framework';

export const USERS_TABLE_PAGE_SIZE = 30;

export const viewerCompany = gql`
  query ViewerCompany($company_id: String!) {
    viewer {
      user_id

      company(company_id: $company_id) {
        company_id
        company_name
        is_carrier
        is_shipper
        arePlacesEnabled
        onboardingStatus: unifiedOnboardingStatus
        defaultVehicleDwellTimeThresholdMinutes
        shouldSeeUpcomingTransportsTab

        isUIDisabled: featureFlag(flagName: "disable_customer_ui")
        isOceanUIEnabled: featureFlag(flagName: "enable_ocean_ui")
        isAirUIEnabled: featureFlag(flagName: "enable_air_ui")
        isInvitationFlowDisabled: featureFlag(flagName: "disable_invitation_flow")

        me {
          profile {
            user_id
            first_name
            last_name
            email
          }

          role
          transporeonPlatform {
            userIdOnPlatform
          }

          configuration {
            vehicleDwellTimeThresholdMinutes
          }
        }
      }
    }
  }
`;
export const viewerCompanies = gql`
  query ViewerCompanies {
    viewer {
      user_id

      companies {
        company_id
        company_name
        is_carrier
        is_shipper
        onboardingStatus: unifiedOnboardingStatus
      }
    }
  }
`;

export const companyUsers = gql`
  query CompanyUsers($company_id: String!, $first: Int, $cursor: String) {
    viewer {
      user_id

      company(company_id: $company_id) {
        company_id
        is_shipper
        isPresentOnTransporeonPlatform

        users {
          users(first: $first, after: $cursor) {
            edges {
              node {
                profile {
                  user_id
                  first_name
                  last_name
                  email
                }

                role
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
          count
        }
      }
    }
  }
`;

export const removeUserFromCompany = gql`
  mutation RemoveUserFromCompany($input: RemoveUserFromCompanyInput!) {
    removeUserFromCompany(input: $input)
  }
`;

export const changeUserRoleInCompany = gql`
  mutation ChangeUserRoleInCompany($input: ChangeUserRoleInCompanyInput!) {
    changeUserRoleInCompany(input: $input) {
      profile {
        user_id
        first_name
        last_name
        email
      }

      role
    }
  }
`;

export const addUserToCompany = gql`
  mutation AddUserToCompany($input: AddUserToCompanyInput!) {
    addUserToCompany(input: $input) {
      profile {
        user_id
        first_name
        last_name
        email
      }

      role
    }
  }
`;

export const isPresentOnTransporeonPlatform = gql`
  query IsPresentOnTransporeonPlatform($company_id: String!) {
    viewer {
      user_id
      company(company_id: $company_id) {
        company_id
        isPresentOnTransporeonPlatform
      }
    }
  }
`;

export const updateUserInCompanyConfiguration = gql`
  mutation UpdateUserInCompanyConfiguration($input: UpdateUserInCompanyConfigurationInput!) {
    updateUserInCompanyConfiguration(input: $input) {
      profile {
        user_id
        first_name
        last_name
        email
      }

      role

      configuration {
        vehicleDwellTimeThresholdMinutes
      }
    }
  }
`;
