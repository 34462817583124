import { Heading, Stack, CountBadge } from '@sixfold/common-ui';
import * as React from 'react';

import styles from './styles.module.css';

export interface Props {
  title: React.ReactNode;
  label?: React.ReactNode;
  action?: React.ReactNode;
}

export const Header: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ title, label, action, ...rest }) => (
  <div {...rest} className={styles.header}>
    <Heading size="medium">
      {label ? (
        <Stack alignItems="center">
          {title}
          <CountBadge value={label} size="small" />
        </Stack>
      ) : (
        title
      )}
    </Heading>
    {action !== undefined && action}
  </div>
);
