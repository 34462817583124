import { useForm } from '@sixfold/app-utils';
import { Input, Stack } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import * as React from 'react';

export type UserFormData = {
  firstName: string;
  lastName: string;
  email: string;
};

export interface Props {
  onSubmit: (user: UserFormData) => Promise<void> | void;
  id: string;
}

export const CompanyUserForm: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  onSubmit: propOnSubmit,
  id,
}) => {
  const { form, errors, onChange, onSubmit } = useForm<UserFormData>({
    validateFields: {
      firstName: { exist: 'Enter first name' },
      lastName: { exist: 'Enter last name' },
      email: { exist: 'Enter email', email: 'Enter email' },
    },
    initialValues: { firstName: '', lastName: '', email: '' },
    onSubmit: propOnSubmit,
  });

  return (
    <Stack as="form" onSubmit={onSubmit} id={id} direction="column" gap="medium">
      <Input
        invalid={errors.firstName !== undefined}
        label={<Localized id="company.users.form.firstName">First name</Localized>}
        name="firstName"
        value={form.firstName}
        onChange={({ event }) => onChange(event)}
      />
      <Input
        invalid={errors.lastName !== undefined}
        label={<Localized id="company.users.form.lastName">Last name</Localized>}
        name="lastName"
        value={form.lastName}
        onChange={({ event }) => onChange(event)}
      />
      <Input
        invalid={errors.email !== undefined}
        label={<Localized id="company.users.form.email">Email</Localized>}
        name="email"
        value={form.email}
        onChange={({ event }) => onChange(event)}
      />
    </Stack>
  );
};
