import { KeyArgsFunction } from '@apollo/client/cache/inmemory/policies';
import { pickBy } from '@sixfold/typed-primitives';

const paginationKeys = ['first', 'after', 'last', 'before'];

export const paginationKeyArgs: KeyArgsFunction = (args) => {
  if (args === null) {
    return false;
  }

  const keyArguments = Array.from(
    Object.keys(pickBy(args, (value, key) => value !== undefined && !paginationKeys.includes(key))),
  );

  if (keyArguments.length === 0) {
    return false;
  }

  return keyArguments;
};
