import { Spinner } from '@sixfold/common-ui';
import * as React from 'react';

import styles from './styles.module.css';

export const LoadingIndicator: React.FunctionComponent<React.PropsWithChildren<unknown>> = React.memo(
  function LoadingIndicator() {
    return (
      <div className={styles.indicator}>
        <Spinner />
      </div>
    );
  },
);
