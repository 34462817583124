import { ErrorBoundary as PrimitiveErrorBoundary } from '@sixfold/app-utils';
import { Localized } from '@sixfold/localization-component';
import classnames from 'classnames';
import * as React from 'react';

import styles from './styles.module.css';

export const ErrorBoundary: React.FunctionComponent<
  React.PropsWithChildren<{ className?: string; compact?: boolean }>
> = ({ children, compact = true, className }) => {
  const renderError = React.useCallback(
    () => (
      <div className={classnames({ [styles.error]: true, [styles.compact]: compact }, className)}>
        <h1>
          <Localized id="component.error.unknown.message">Something went wrong</Localized>
        </h1>
      </div>
    ),
    [compact, className],
  );

  return <PrimitiveErrorBoundary renderError={renderError}>{children}</PrimitiveErrorBoundary>;
};

export function withErrorBoundary<Props extends object>(Component: React.FunctionComponent<Props>) {
  const result: React.FunctionComponent<Props> = (props) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );

  result.displayName = `withErrorBoundary(${Component.displayName ?? Component.name})`;
  return result;
}
