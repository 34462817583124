import { RouterProvider as UIRouterProvider } from '@sixfold/common-ui';
import * as React from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

type RouterProviderProps = {
  children: React.ReactNode;
};

export const RouterProvider = ({ children }: RouterProviderProps) => {
  const { push } = useHistory();
  const { pathname: locationPathname } = useLocation();

  const getCurrentPropValue = React.useCallback(
    (href: string) => {
      // Discard query string and hash from href for matching.
      const hrefPathname = new URL(href, window.location.origin).pathname;
      const match = matchPath(locationPathname, hrefPathname);

      if (match === null) {
        return undefined;
      }

      // If the match is exact, then the current route is the current page.
      if (match.isExact) {
        return 'page';
      }

      // Otherwise it's a parent page.
      return true;
    },
    [locationPathname],
  );

  return (
    <UIRouterProvider navigate={push} getCurrentPropValue={getCurrentPropValue}>
      {children}
    </UIRouterProvider>
  );
};
