import {
  Button,
  Dropdown,
  DropdownButton,
  DropdownMenu,
  DropdownMenuItem,
  AlertModal,
  useConfirmation,
  Paragraph,
  Link,
  FocusAreaControl,
} from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import * as React from 'react';

import { UserRoleInCompany } from '../../../lib/graphql';
import { UserInCompany } from '../../entities';
import { getUserName } from '../../lib/entities';

export interface Props {
  user: UserInCompany;
  removeUserFromCompany: (user: UserInCompany) => Promise<void>;
  changeUserToCompanyAdmin: (user: UserInCompany) => Promise<void>;
  isCompanyOnTransporeon: boolean;
}

export const CompanyUserDropdown: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  user,
  removeUserFromCompany,
  changeUserToCompanyAdmin,
  isCompanyOnTransporeon,
}) => {
  const makeAdminItemRef = React.useRef<HTMLLIElement | null>(null);
  const makeAdminConfirmation = useConfirmation();
  const handleMakeAdminAction = React.useCallback(async () => {
    const confirmed = await makeAdminConfirmation.confirm();

    if (confirmed) {
      await changeUserToCompanyAdmin(user);
    }

    return confirmed;
  }, [user, makeAdminConfirmation, changeUserToCompanyAdmin]);

  const removeUserItemRef = React.useRef<HTMLLIElement | null>(null);
  const removeUserConfirmation = useConfirmation();
  const handleRemoveUserAction = React.useCallback(async () => {
    const confirmed = await removeUserConfirmation.confirm();

    if (confirmed) {
      await removeUserFromCompany(user);
    }

    return confirmed;
  }, [user, removeUserConfirmation, removeUserFromCompany]);

  return (
    <>
      <Dropdown id={`user-${user.profile.user_id}-actions-dropdown`} align="end">
        <FocusAreaControl>
          <DropdownButton
            size="small"
            kind="neutral"
            data-test-id="user-role"
            iconStart="small-more-hor"
            hideLabel={true}>
            <Localized id="company.users.dropdown.actions.button.title">Actions</Localized>
          </DropdownButton>
        </FocusAreaControl>
        <DropdownMenu>
          {user.role === UserRoleInCompany.REGULAR_USER && (
            <DropdownMenuItem
              icon="small-star"
              ref={makeAdminItemRef}
              onClick={handleMakeAdminAction}
              data-role="make-admin"
              data-id={user.profile.user_id}>
              <Localized id="company.users.dropdown.makeAdmin.title">Make admin</Localized>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            icon="small-bin"
            ref={removeUserItemRef}
            onClick={handleRemoveUserAction}
            data-role="remove-user">
            <Localized id="company.users.dropdown.removeUser.title">Remove user</Localized>
          </DropdownMenuItem>
        </DropdownMenu>
        <AdminConfimationModal
          trigger={makeAdminItemRef.current}
          user={user}
          pending={makeAdminConfirmation.pending}
          cancel={makeAdminConfirmation.cancel}
          proceed={makeAdminConfirmation.proceed}
        />
        <RemoveConfimationModal
          trigger={removeUserItemRef.current}
          user={user}
          pending={removeUserConfirmation.pending}
          cancel={removeUserConfirmation.cancel}
          proceed={removeUserConfirmation.proceed}
          isCompanyOnTransporeon={isCompanyOnTransporeon}
        />
      </Dropdown>
    </>
  );
};

const AdminConfimationModal: React.FunctionComponent<
  React.PropsWithChildren<{
    trigger: HTMLElement | null;
    user: UserInCompany;
    pending: boolean;
    cancel?: () => void;
    proceed?: () => void;
  }>
> = ({ trigger, user, pending, cancel, proceed }) => {
  const userName = getUserName(user.profile);
  return (
    <AlertModal
      open={pending}
      trigger={trigger}
      onClose={cancel}
      title={
        <Localized id="company.users.adminConfimationModal.content.title">
          Are you sure you want to make {{ name: userName }} an admin?
        </Localized>
      }
      description={
        <Paragraph>
          <Localized id="company.users.adminConfimationModal.content.description">
            Making {{ name: userName }} admin allows them to create company filters, create customer portals and remove
            users from Visibility Hub.
          </Localized>
        </Paragraph>
      }>
      <Button onClick={cancel} kind="subtle" size="medium">
        <Localized id="company.users.adminConfimationModal.cancel.title">Cancel</Localized>
      </Button>
      <Button kind="primary" size="medium" data-role="modal-confirm-admin" onClick={proceed}>
        <Localized id="company.users.adminConfimationModal.confirm.title">Make admin</Localized>
      </Button>
    </AlertModal>
  );
};

const RemoveConfimationModal: React.FunctionComponent<
  React.PropsWithChildren<{
    trigger: HTMLElement | null;
    user: UserInCompany;
    pending: boolean;
    cancel?: () => void;
    proceed?: () => void;
    isCompanyOnTransporeon: boolean;
  }>
> = ({ trigger, user, pending, cancel, proceed, isCompanyOnTransporeon }) => {
  const userName = getUserName(user.profile);
  return (
    <AlertModal
      open={pending}
      trigger={trigger}
      onClose={cancel}
      title={
        <Localized id="company.users.removeConfimationModal.content.title">
          Are you sure you want to remove {{ name: userName }} from Visibility Hub?
        </Localized>
      }
      description={
        <Paragraph>
          {isCompanyOnTransporeon ? (
            <Localized id="company.users.removeConfimationModal.content.description.TP">
              {{ name: userName }} will only be removed from Visibility Hub. Please reach out to{' '}
              <Link href={'mailto:support@transporeon.com'}>support@transporeon.com</Link> to delete this user also from
              Transporeon.
            </Localized>
          ) : (
            <Localized id="company.users.removeConfimationModal.content.description">
              Removing {{ name: userName }} from your company will deny them access to Visibility Hub.
            </Localized>
          )}
        </Paragraph>
      }>
      <Button onClick={cancel} kind="subtle" size="medium">
        <Localized id="company.users.removeConfimationModal.cancel.title">Cancel</Localized>
      </Button>
      <Button kind="danger" size="medium" data-role="modal-confirm-remove" onClick={proceed}>
        <Localized id="company.users.removeConfimationModal.confirm.title">Remove user</Localized>
      </Button>
    </AlertModal>
  );
};
