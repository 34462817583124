import { SpinnerOverlay } from '@sixfold/common-ui';
import React from 'react';
import { Redirect } from 'react-router';

import { Routes } from './index';
import { useViewerCompanyQuery } from '../company';
import { ErrorPage } from '../components/error_page';

export const HomeRedirectRoute: React.FunctionComponent<
  React.PropsWithChildren<{ companyId: string; defaultRoutePath: string }>
> = ({ companyId, defaultRoutePath }) => {
  const { loading, data, error } = useViewerCompanyQuery(companyId);

  if (loading) {
    return <SpinnerOverlay />;
  }

  if (data?.company.is_carrier && data?.company.onboardingStatus !== 'ONBOARDED') {
    return <Redirect to={Routes.LandingPage.generatePath({ company_id: companyId })} />;
  }

  if (error !== undefined && data === undefined) {
    return <ErrorPage type={error.type} message={error.message} />;
  }

  if (data === undefined) {
    return <ErrorPage />;
  }

  return <Redirect to={defaultRoutePath} />;
};
