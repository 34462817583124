import { useQuery } from '@sixfold/app-data-framework';
import { useErrorHandler } from '@sixfold/app-utils';
import { SpinnerOverlay } from '@sixfold/common-ui';
import { CustomError } from '@sixfold/typed-primitives';
import * as React from 'react';

import { ErrorPage } from '../../components/error_page';
import { ViewerCompaniesQuery } from '../../lib/graphql';
import { Company } from '../entities';
import { viewerCompanies } from '../graphql';

export interface ViewerCompanyProps {
  children?: (company: Company) => React.ReactElement;
}

export const ViewerCompanyContainer: React.FunctionComponent<ViewerCompanyProps> = ({ children }) => {
  const { isLoading, error, companies } = useViewerCompanies();
  const errorHandler = useErrorHandler();

  if (isLoading) {
    return <SpinnerOverlay />;
  }

  if (error !== undefined && companies === undefined) {
    // Only errors that prevent us from getting any data back should be propagated
    throw error;
  }

  if (children === undefined) {
    return null;
  }

  if (companies === undefined || companies.length === 0) {
    errorHandler?.captureError(new CustomError('Failed to fetch companies for viewer'));
    return <ErrorPage type="not-found" />;
  }

  // TODO: Eventually we could display a picker here where the user could choose which company they want to see
  // Once picked, the children can be rendered with said company ID
  const company = companies[0];
  return children(company);
};

export function useViewerCompanies() {
  const result = useQuery<ViewerCompaniesQuery>(viewerCompanies);

  const companies = React.useMemo(
    () =>
      result.data?.viewer?.companies?.map((company) => ({
        company_id: company.company_id,
        company_name: company.company_name,
        is_carrier: company.is_carrier === true,
        is_shipper: company.is_shipper === true,
        onboardingStatus: company.onboardingStatus,
      })) ?? undefined,
    [result.data],
  );

  return React.useMemo(() => ({ companies, isLoading: result.loading, error: result.error }), [result, companies]);
}
