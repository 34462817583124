import { useId } from '@sixfold/app-framework';
import { Button, Modal, ModalFooter, ModalHeader, ModalMain, Stack } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import * as React from 'react';

import { Header } from '../../../components/header';
import { UserInCompany } from '../../entities';
import { CompanyUserForm } from '../company_user_form';

export interface Props {
  addUser: (user: { firstName: string; lastName: string; email: string }) => Promise<UserInCompany | void>;
  users: UserInCompany[];
  isLoading: boolean;
  isAllowedToAddUsers: boolean;
  count: number;
}

export const CompanyUsersHeader: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  addUser,
  isAllowedToAddUsers,
  count,
}) => {
  return (
    <Header
      title={<Localized id="company.users.header.title">Users</Localized>}
      action={isAllowedToAddUsers && <AddUserModal addUser={addUser} />}
      data-test-id="companyUsersHeader"
      label={count}
    />
  );
};

const AddUserModal = ({ addUser }: Pick<Props, 'addUser'>) => {
  const formId = useId();
  const [isAddingUserFormOpen, setIsAddingUserFormOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  const handleAddUserToCompany = React.useCallback(
    async (user: { firstName: string; lastName: string; email: string }) => {
      setIsSaving(true);
      await addUser(user);

      setIsSaving(false);
      setIsAddingUserFormOpen(false);
    },
    [addUser],
  );

  return (
    <Modal
      trigger={
        <Button size="small" kind="primary">
          <Localized id="company.users.header.addUser.button.title">Add user</Localized>
        </Button>
      }
      open={isAddingUserFormOpen}
      onToggle={setIsAddingUserFormOpen}>
      <ModalHeader title={<Localized id="company.users.header.addUser.title">Add a user</Localized>} />
      <ModalMain>
        <CompanyUserForm onSubmit={handleAddUserToCompany} id={formId} />
      </ModalMain>
      <ModalFooter>
        <Stack justifyContent="end">
          <Button kind="primary" size="medium" type="submit" form={formId} loading={isSaving}>
            <Localized id="company.users.form.submit.button.title">Add user</Localized>
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};
