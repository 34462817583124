import { useErrorHandler, lazyWithRetry } from '@sixfold/app-utils';
import { SpinnerOverlay } from '@sixfold/common-ui';
import { CustomError } from '@sixfold/typed-primitives';
import React, { Suspense } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import { ViewerCompanyContainer } from '../company';
import { ErrorPage } from '../components/error_page';
import { useTrackLoginTypeEffect } from '../lib/util/use_track_login_type_effect';
import { Routes } from '../routes';
import { HomeRedirectRoute } from '../routes/home_redirect';

const SupportedCompanyRoute = lazyWithRetry(() =>
  import('../routes/supported_company').then(({ SupportedCompanyRoute }) => ({ default: SupportedCompanyRoute })),
);
const GoRedirectRoute = lazyWithRetry(() =>
  import('../routes/go_redirect').then(({ GoRedirectRoute }) => ({ default: GoRedirectRoute })),
);

interface Props {
  logOut: () => void;
}

export const App: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ logOut }) => {
  useTrackLoginTypeEffect();
  return (
    <Switch>
      <Route
        path={Routes.Supported.routerPath}
        render={() => (
          <Suspense fallback={<SpinnerOverlay />}>
            <SupportedCompanyRoute logOut={logOut} />
          </Suspense>
        )}
      />
      <Route
        key={Routes.GoRedirect.routerPath}
        path={Routes.GoRedirect.routerPath}
        render={() => (
          <ViewerCompanyContainer>
            {({ company_id }) => (
              <Suspense fallback={<SpinnerOverlay />}>
                <GoRedirectRoute companyId={company_id} />
              </Suspense>
            )}
          </ViewerCompanyContainer>
        )}
      />
      <Route path={Routes.Unsupported.routerPath} render={() => <UnsupportedRoute />} />
      <Route path={Routes.Company.routerPath} render={() => <CompanyRoute />} />
      <Route
        render={() => (
          <ViewerCompanyContainer>
            {({ company_id }) => (
              <HomeRedirectRoute
                companyId={company_id}
                defaultRoutePath={Routes.DataNetwork.generatePath({ company_id })}
              />
            )}
          </ViewerCompanyContainer>
        )}
      />
    </Switch>
  );
};

const UnsupportedRoute = () => {
  const { unsupported_view } = useParams<Routes.Unsupported>();
  const errorHandler = useErrorHandler();
  errorHandler?.captureError(new CustomError(`Trying to access unsupported path - ${unsupported_view}`));
  return <ErrorPage type="not-found" />;
};

const CompanyRoute = () => {
  const { company_id } = useParams<Routes.Company>();
  return <HomeRedirectRoute companyId={company_id} defaultRoutePath={Routes.Transports.generatePath({ company_id })} />;
};
