import { useAnalytics } from '@sixfold/app-analytics-framework';
import { isNil } from '@sixfold/typed-primitives';
import React from 'react';

import { RememberLoginDetails } from '../../../shared/types/sixfold_last_login';
import { EmbedDataContext } from '../../components/embed_data_context';

export const useTrackLoginTypeEffect = () => {
  const analytics = useAnalytics();
  const isSessionTrackedRef = React.useRef(false);

  const {
    embedData: { sixfold_last_login },
  } = React.useContext(EmbedDataContext);

  const lastLoginDetails: RememberLoginDetails = React.useMemo(
    () => JSON.parse(sixfold_last_login ?? '{}'),
    [sixfold_last_login],
  );

  React.useEffect(() => {
    // don't run effect until analytics is initialized
    if (isNil(analytics)) {
      return;
    }

    // wait until context is set
    if (isNil(lastLoginDetails)) {
      return;
    }

    // track only once per mount
    if (isSessionTrackedRef.current === true) {
      return;
    }

    analytics?.track('Started a new session', { type: lastLoginDetails?.type ?? 'unknown' });
    isSessionTrackedRef.current = true;
  }, [analytics, lastLoginDetails]);
};
