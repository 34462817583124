import { Localized } from '@sixfold/localization-component';
import * as React from 'react';

import styles from './styles.module.css';

export interface ErrorPageProps {
  type?: 'not-found' | 'unknown';
  message?: React.ReactNode;
}

export const ErrorPage: React.FunctionComponent<React.PropsWithChildren<ErrorPageProps>> = ({
  type = 'unknown',
  message,
}) => (
  <div className={styles.error}>
    <h1 className={styles.message}>
      {message ||
        (type === 'unknown' ? (
          <Localized id="component.error.unknown.message">Something went wrong</Localized>
        ) : (
          <Localized id="component.error.notFound.message">Page not found</Localized>
        ))}
    </h1>
  </div>
);
