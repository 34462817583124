import { QueryResult } from '@sixfold/app-data-framework';
import { OperationVariables } from '@sixfold/app-framework';
import { isNil, notNil } from '@sixfold/typed-primitives';

// prettier-ignore
type Connection<T> = {
  edges?: ({
    node?: T | null,
  } | null)[] | null,
  pageInfo?: {
    hasNextPage?: boolean | null;
    endCursor?: string | null;
  } | null;
} | null;

export function getNodes<T>(items: Connection<T> | undefined): T[] {
  const edges = (items ? items.edges : []) || [];
  return edges.map((edge) => edge && edge.node).filter(notNil);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function path<T>(keys: string[], object: any): T {
  return keys.reduce((object, next) => {
    if (object === null || object === undefined) {
      return undefined;
    }

    return object[next];
  }, object);
}

export const loadMoreFromConnection = async <TData, TVariables extends OperationVariables>(
  result: QueryResult<TData, TVariables>,
  connectionKeyPath: string[],
  pageSize?: number,
) => {
  const connection = path<Connection<TData>>(connectionKeyPath, result.data);

  if (
    !result.data ||
    !result.fetchMore ||
    !connection ||
    !connection.pageInfo ||
    isNil(connection.pageInfo.hasNextPage) ||
    !connection.pageInfo.hasNextPage
  ) {
    return;
  }

  const variables = {
    ...(pageSize !== undefined ? { first: pageSize } : {}),
    cursor: connection.pageInfo.endCursor ?? undefined,
  };

  return await result.fetchMore({ variables });
};
