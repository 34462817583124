import { useAnalytics } from '@sixfold/app-analytics-framework';
import { useErrorHandler, useInterval, useNetworkStatus } from '@sixfold/app-utils';
import { useAnnouncements } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import { NestedError } from '@sixfold/typed-primitives';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { EmbedDataContext } from '../embed_data_context';

const UPDATE_ANNOUNCEMENT_KEY = 'APP_UPDATED';
const OFFLINE_ANNOUNCEMENT_KEY = 'NETWORK_OFFLINE';
const INFO_ANNOUNCEMENT_KEY = 'INFO_ANNOUNCEMENT';

export const UpdateNotifier: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation();
  const { embedData, embedConfig } = React.useContext(EmbedDataContext);
  const analytics = useAnalytics();
  const errorHandler = useErrorHandler();
  const [isOutdated, setIsOutdated] = React.useState<boolean>(false);

  const { isOnline } = useNetworkStatus();
  const announce = useAnnouncements();
  const lastLocation = React.useRef(location.pathname);

  const reloadPage = React.useCallback(
    (trigger: 'navigation' | 'user') => {
      analytics?.track('Reloading app to get new version', { trigger });
      window.location.reload();
    },
    [analytics],
  );

  const onRefreshButtonClick = React.useCallback(() => {
    reloadPage('user');
  }, [reloadPage]);

  const checkManifest = React.useCallback(async () => {
    if (isOutdated) {
      return;
    }

    try {
      // HACK: Polyfilled fetch does not support "cache" option, so we have to work around this with a cache-busting query param
      const response = await window.fetch(`/js/version.txt?timestamp=${new Date().getTime()}`);
      const fsAppVersion = await response.text();
      const currentAppVersion = embedConfig?.release;

      const versionsEqual = fsAppVersion === currentAppVersion;
      if (!versionsEqual) {
        analytics?.track('App version is out of date');

        announce.info({
          key: UPDATE_ANNOUNCEMENT_KEY,
          title: (
            <Localized id="component.updateNotifier.outdated.title">Newer version of the app is available</Localized>
          ),
          message: (
            <Localized id="component.updateNotifier.outdated.description">
              We just launched a newer version of our app, please refresh the page
            </Localized>
          ),
          action: {
            title: <Localized id="component.updateNotifier.outdated.refresh.button.title">Refresh</Localized>,
            onClick: () => {
              onRefreshButtonClick();
              return true;
            },
          },
        });

        setIsOutdated(true);
      }
    } catch (e) {
      errorHandler?.captureError(new NestedError('Failed to fetch version file for update check', e));
    }
  }, [isOutdated, embedConfig?.release, analytics, announce, onRefreshButtonClick, errorHandler]);

  // Check the manifest every 5 minutes
  useInterval(checkManifest, 300_000);

  useEffect(() => {
    if (!isOutdated || location.pathname === lastLocation.current) {
      return;
    }

    lastLocation.current = location.pathname;
    // Assumption is that a location change is a safe moment to automatically trigger
    // a reload (i.e nothing should be pending)
    reloadPage('navigation');
    // React needs that this hook defined isOutdated as a dependency, but that would cause the page to instantly reload.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, reloadPage]);

  // Offline notice
  useEffect(() => {
    if (isOnline) {
      return;
    }

    announce.error({
      key: OFFLINE_ANNOUNCEMENT_KEY,
      title: <Localized id="component.updateNotifier.offline.title">Your internet connection is offline</Localized>,
      message: <Localized id="component.updateNotifier.offline.description">Please check your connection</Localized>,
    });

    return () => {
      announce.dismiss(OFFLINE_ANNOUNCEMENT_KEY);
    };
  }, [announce, isOnline]);

  // Info announcement from embedded data
  useEffect(() => {
    if (embedData.info_announcement_message === undefined) {
      return;
    }

    announce.info({
      key: INFO_ANNOUNCEMENT_KEY,
      title: embedData.info_announcement_message,
    });

    return () => {
      announce.dismiss(INFO_ANNOUNCEMENT_KEY);
    };
  }, [announce, embedData.info_announcement_message]);

  // We don't render components
  return null;
};
