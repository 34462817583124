import Cookies from 'js-cookie';

import { getEmbedConfig } from '../data';

const getTrackingCookieName = () => getEmbedConfig()?.tracking_cookie_name;

function readConsentCookie(): 'ok' | 'nok' | undefined {
  const cookieName = getTrackingCookieName();
  const value = cookieName !== undefined ? Cookies.get(cookieName) : undefined;
  if (value === 'ok' || value === 'nok') {
    return value;
  }

  return undefined;
}

export function setConsentCookie({
  cookieDomain,
  isConsentGiven,
}: {
  cookieDomain: string | undefined;
  isConsentGiven: boolean;
}) {
  const cookieName = getTrackingCookieName();
  const value = isConsentGiven ? 'ok' : 'nok';
  if (cookieName !== undefined) {
    Cookies.set(cookieName, value, { domain: cookieDomain, expires: 365 * 10, SameSite: 'none', secure: true });
  }
}

export const getIsConsentGiven = () => readConsentCookie() === 'ok';
export const shouldAskForConsent = () => {
  const cookieName = getTrackingCookieName();

  if (cookieName === undefined) {
    return false;
  }

  const consentCookieValue = readConsentCookie();
  return consentCookieValue !== 'ok' && consentCookieValue !== 'nok';
};
