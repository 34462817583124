import { ErrorPage } from '../../../components/error_page';
import { LoadingIndicator } from '../../../components/loading_indicator';
import { UserRoleInCompany } from '../../../lib/graphql';
import { CompanyUsersHeader } from '../../components/company_users_header';
import { CompanyUsersList } from '../../components/company_users_list';
import { useUsersInCompany } from '../../hooks';
import { useCompanyUsersMutations } from '../company_users_mutations';
import { useViewerCompany } from '../hooks';

import styles from './styles.module.css';

export const CompanyUsers = () => {
  const { removeUser, addUser, makeAdmin } = useCompanyUsersMutations();
  const { isLoading, queryResult, countResult, isPresentOnTpPlatform, hasNextPage, loadNextPage } = useUsersInCompany();
  const { userInCompany } = useViewerCompany();

  const isAllowedToAddUsers = userInCompany?.role === UserRoleInCompany.ADMIN && !isPresentOnTpPlatform;

  if (queryResult === undefined && !isLoading) {
    return <ErrorPage type="not-found" />;
  }

  return (
    <div className={styles.container}>
      <CompanyUsersHeader
        users={queryResult}
        isLoading={isLoading}
        addUser={addUser}
        isAllowedToAddUsers={isAllowedToAddUsers}
        count={countResult}
      />

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <CompanyUsersList
          users={queryResult}
          hasNextPage={hasNextPage}
          loadNextPage={loadNextPage}
          removeUserFromCompany={removeUser}
          changeUserToCompanyAdmin={makeAdmin}
          isCompanyOnTransporeon={isPresentOnTpPlatform}
          isViewingUserAdmin={userInCompany?.role === UserRoleInCompany.ADMIN}
        />
      )}
    </div>
  );
};
