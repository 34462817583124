import { useLocalStorage } from '@sixfold/app-utils';
import { ThemeProvider, ThemeObject } from '@sixfold/common-ui';
import { transporeonLightClassName, transporeonDarkClassName } from '@sixfold/common-ui/dist/lib/css';
import transporeonDark from '@sixfold/design-tokens/build/transporeon/json/variables-dark.json';
import transporeonLight from '@sixfold/design-tokens/build/transporeon/json/variables.json';
import iconsUrl from '@sixfold/icons/dist/sprite.svg';
import * as React from 'react';

import { getEmbedConfig } from './lib/data';

import '@sixfold/common-ui/dist/lib/styles/global.css';
import '@sixfold/design-tokens/build/transporeon/css/fonts.css';
import '@sixfold/design-tokens/build/transporeon/css/variables.css';
import '@sixfold/design-tokens/build/transporeon/css/variables-dark.css';

const embedConfig = getEmbedConfig();

const themes: Record<string, ThemeObject> = {
  'transporeon-light': {
    className: transporeonLightClassName,
    variables: transporeonLight,
  },
  'transporeon-dark': {
    className: transporeonDarkClassName,
    variables: transporeonDark,
  },
};

// Define the function externally, so its identity would remain the same across rerenders.
const getIconPath = (name: string) => `${iconsUrl}#${name}`;

export const ThemeWrapper: React.FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [theme, setTheme] = useStoredTheme();

  const currentTheme = embedConfig?.show_common_ui_theme_picker ? (theme as string) : 'transporeon-light';

  return (
    <ThemeProvider currentTheme={currentTheme} themes={themes} onThemeChange={setTheme} getIconPath={getIconPath}>
      {children}
    </ThemeProvider>
  );
};

const useStoredTheme = () => {
  return useLocalStorage<string>('common-ui-theme', 'transporeon-light');
};
