import { Avatar, Box, Pill, Stack, Text } from '@sixfold/common-ui';
import { Localized } from '@sixfold/localization-component';
import React from 'react';

import { CompanyUserProfile, UserRoleInCompany } from '../../../lib/graphql';
import { UserInCompany } from '../../entities';
import { CompanyUserDropdown } from '../company_user_dropdown';

import * as styles from './company_users_item.css';

export const UsersListItem: React.FunctionComponent<
  React.PropsWithChildren<{
    role: UserRoleInCompany;
    profile: CompanyUserProfile;
    removeUserFromCompany: (user: UserInCompany) => Promise<void>;
    changeUserToCompanyAdmin: (user: UserInCompany) => Promise<void>;
    isCompanyOnTransporeon: boolean;
    isViewingUserAdmin: boolean;
  }>
> = ({
  role,
  profile,
  removeUserFromCompany,
  changeUserToCompanyAdmin,
  isCompanyOnTransporeon,
  isViewingUserAdmin,
}) => {
  return (
    <Box className={styles.card} padding="small" paddingInline="medium" radius="small">
      <Stack alignItems="center" justifyContent="space-between" gap="medium" direction="row" wrap={false}>
        <Stack alignItems="center" gap="medium" wrap={false}>
          <Avatar size="medium" firstName={profile.first_name ?? undefined} lastName={profile.last_name ?? undefined} />
          <Stack direction="column" gap="xsmall">
            <Stack alignItems="center">
              <Text size="medium" weight="bold">
                {profile.first_name} {profile.last_name}
              </Text>
              {role === UserRoleInCompany.ADMIN && (
                <Pill kind="success">
                  <Localized id="company.users.table.admin">Admin</Localized>
                </Pill>
              )}
            </Stack>
            <Text size="small" prominence="subtle">
              {profile.email}
            </Text>
          </Stack>
        </Stack>
        {isViewingUserAdmin && (
          <CompanyUserDropdown
            user={{ role, profile }}
            removeUserFromCompany={removeUserFromCompany}
            changeUserToCompanyAdmin={changeUserToCompanyAdmin}
            isCompanyOnTransporeon={isCompanyOnTransporeon}
          />
        )}
      </Stack>
    </Box>
  );
};
