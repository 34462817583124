import { gql } from '@sixfold/app-data-framework';

export const changeUserProfile = gql`
  mutation ChangeUserProfile($input: UpdateViewerProfileInput!) {
    updateViewerProfile(input: $input) {
      user_id
      locale
    }
  }
`;

export const userProfile = gql`
  query UserProfile($companyId: String!, $skipCompany: Boolean!) {
    viewer {
      user_id
      email
      first_name
      last_name
      locale
      company(company_id: $companyId) @skip(if: $skipCompany) {
        company_id
        me {
          role
        }
      }
    }
  }
`;
