import { InfiniteList, useScrollParent } from '@sixfold/common-ui';
import React from 'react';

import { CompanyUser } from '../../../lib/graphql';
import { UserInCompany } from '../../entities';
import { UsersListItem } from '../company_users_item';

export const CompanyUsersList: React.FunctionComponent<
  React.PropsWithChildren<{
    users: CompanyUser[];
    removeUserFromCompany: (user: UserInCompany) => Promise<void>;
    changeUserToCompanyAdmin: (user: UserInCompany) => Promise<void>;
    isCompanyOnTransporeon: boolean;
    isViewingUserAdmin: boolean;
    hasNextPage: boolean;
    loadNextPage: () => Promise<void>;
  }>
> = ({
  users,
  removeUserFromCompany,
  changeUserToCompanyAdmin,
  isCompanyOnTransporeon,
  isViewingUserAdmin,
  hasNextPage,
  loadNextPage,
}) => {
  const getItemKey = React.useCallback((_: unknown, user: CompanyUser) => user.profile.user_id, []);

  const renderItem = React.useCallback(
    ({ item: { role, profile } }: { item: CompanyUser }) => (
      <UsersListItem
        role={role}
        profile={profile}
        removeUserFromCompany={removeUserFromCompany}
        changeUserToCompanyAdmin={changeUserToCompanyAdmin}
        isCompanyOnTransporeon={isCompanyOnTransporeon}
        isViewingUserAdmin={isViewingUserAdmin}
      />
    ),
    [removeUserFromCompany, changeUserToCompanyAdmin, isCompanyOnTransporeon, isViewingUserAdmin],
  );

  const scrollParent = useScrollParent();

  return (
    <InfiniteList
      // overscan={500} // Using overscan causes an infinite loop in react-virtuoso: https://github.com/petyosi/react-virtuoso/issues/946#issuecomment-1711593687
      data={users}
      itemKey={getItemKey}
      itemContent={renderItem}
      loadMoreData={hasNextPage ? loadNextPage : undefined}
      scrollParent={scrollParent}
      gap="small"
    />
  );
};
